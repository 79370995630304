// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Base$BsConsole = require("./Base.js");
var I18N$BsConsole = require("../../I18N.js");
var Colors$BsConsole = require("../../Colors.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var L10N_date_time$BsConsole = require("../../L10N_date_time.js");
var Issues_assignee$BsConsole = require("./Issues_assignee.js");

function toString(param) {
  switch (param) {
    case /* StateC */0 :
        return "fingerprint;issues;state";
    case /* TagsC */1 :
        return "fingerprint;issues;tags";
    case /* AssigneesC */2 :
        return "fingerprint;issues;assignee";
    case /* TicketsC */3 :
        return "fingerprint;issues;ticket";
    case /* InvariantsC */4 :
        return "fingerprint;issues;invariants";
    case /* FingerprintIdC */5 :
        return "fingerprint;issues;id";
    
  }
}

var Column = {
  toString: toString
};

function toString$1(v) {
  switch (v) {
    case /* Open */0 :
        return "open";
    case /* InProgress */1 :
        return "in-progress";
    case /* Resolved */2 :
        return "resolved";
    case /* Muted */3 :
        return "muted";
    
  }
}

function toStringAsVerbCapitalized(v) {
  switch (v) {
    case /* Open */0 :
        return "Open";
    case /* InProgress */1 :
        return "In progress";
    case /* Resolved */2 :
        return "Resolve";
    case /* Muted */3 :
        return "Mute";
    
  }
}

function toStringAsAdjectiveCapitalized(v) {
  switch (v) {
    case /* Open */0 :
        return "Open";
    case /* InProgress */1 :
        return "In progress";
    case /* Resolved */2 :
        return "Resolved";
    case /* Muted */3 :
        return "Muted";
    
  }
}

function toColor(state) {
  if (state === undefined) {
    return Colors$BsConsole.statusMuted;
  }
  switch (state) {
    case /* Open */0 :
        return Colors$BsConsole.statusOpen;
    case /* InProgress */1 :
        return Colors$BsConsole.statusInProgress;
    case /* Resolved */2 :
        return Colors$BsConsole.statusResolved;
    case /* Muted */3 :
        return Colors$BsConsole.statusMuted;
    
  }
}

function toStringCapitalized(v) {
  switch (v) {
    case /* Open */0 :
        return "Open";
    case /* InProgress */1 :
        return "In progress";
    case /* Resolved */2 :
        return "Resolve";
    case /* Muted */3 :
        return "Mute";
    
  }
}

function ofString(str) {
  switch (str) {
    case "in-progress" :
        return /* InProgress */1;
    case "mute" :
    case "muted" :
        return /* Muted */3;
    case "open" :
        return /* Open */0;
    case "resolve" :
    case "resolved" :
        return /* Resolved */2;
    default:
      return ;
  }
}

function toRgbColor(v) {
  switch (v) {
    case /* Open */0 :
        return Css.hex(Colors$BsConsole.statusOpen);
    case /* InProgress */1 :
        return Css.hex(Colors$BsConsole.statusInProgress);
    case /* Resolved */2 :
        return Css.hex(Colors$BsConsole.statusResolved);
    case /* Muted */3 :
        return Css.hex(Colors$BsConsole.statusMuted);
    
  }
}

var toJson = toString$1;

var State = {
  toString: toString$1,
  toStringAsVerbCapitalized: toStringAsVerbCapitalized,
  toStringAsAdjectiveCapitalized: toStringAsAdjectiveCapitalized,
  toColor: toColor,
  toStringCapitalized: toStringCapitalized,
  ofString: ofString,
  toRgbColor: toRgbColor,
  toJson: toJson,
  columnName: "fingerprint;issues;state"
};

var Tag = { };

function uniq(l) {
  if (!l) {
    return /* [] */0;
  }
  var hd = l[0];
  return /* :: */[
          hd,
          uniq(Belt_List.keep(l[1], (function (v) {
                      return Caml_obj.caml_notequal(v, hd);
                    })))
        ];
}

function toJson$1(v) {
  return Belt_List.toArray(uniq(v)).join(" ");
}

var Tags = {
  uniq: uniq,
  toJson: toJson$1,
  columnName: "fingerprint;issues;tags"
};

function toJson$2(v) {
  return Json_encode.list(Issues_assignee$BsConsole.toJson, v);
}

function uniq$1(l) {
  if (!l) {
    return /* [] */0;
  }
  var hd = l[0];
  return /* :: */[
          hd,
          uniq$1(Belt_List.keep(l[1], (function (v) {
                      return Caml_obj.caml_notequal(v, hd);
                    })))
        ];
}

function getAssigneeUsernames(assignees) {
  return Belt_List.reduce(Belt_List.map(assignees, (function (assignee) {
                    if (assignee.tag) {
                      return Belt_Option.getWithDefault(assignee[0].username, "");
                    } else {
                      return assignee[0].username;
                    }
                  })), /* [] */0, (function (acc, curr) {
                if (Belt_List.has(acc, curr, (function (a, b) {
                          return a === b;
                        })) || curr === "") {
                  return acc;
                } else {
                  return Belt_List.concat(acc, /* :: */[
                              curr,
                              /* [] */0
                            ]);
                }
              }));
}

var Assignees = {
  toJson: toJson$2,
  columnName: "fingerprint;issues;assignee",
  uniq: uniq$1,
  getAssigneeUsernames: getAssigneeUsernames
};

function setAssignees(ticket, assignees) {
  switch (ticket.tag | 0) {
    case /* Unlinked */0 :
        var tick = ticket[0];
        return /* Unlinked */Block.__(0, [{
                    short: tick.short,
                    url: tick.url,
                    assignees: assignees
                  }]);
    case /* Linked */1 :
        var tick$1 = ticket[0];
        return /* Linked */Block.__(1, [{
                    short: tick$1.short,
                    url: tick$1.url,
                    assignees: assignees,
                    watcher: tick$1.watcher
                  }]);
    case /* InteralAssigneeLink */2 :
        return /* InteralAssigneeLink */Block.__(2, [{
                    assignees: assignees,
                    watcher: ticket[0].watcher
                  }]);
    
  }
}

function makeUnlinked($$short, url) {
  return /* Unlinked */Block.__(0, [{
              short: $$short,
              url: url,
              assignees: undefined
            }]);
}

function makeBacktraceIssueTicket(assignees) {
  return /* InteralAssigneeLink */Block.__(2, [{
              assignees: assignees,
              watcher: "_backtrace"
            }]);
}

function ofJson(json) {
  return Json_decode.oneOf(/* :: */[
              (function (json) {
                  var watcher = Json_decode.field("watcher", Json_decode.string, json);
                  if (watcher === "_backtrace") {
                    return /* InteralAssigneeLink */Block.__(2, [{
                                assignees: Json_decode.optional((function (param) {
                                        return Json_decode.field("assignees", (function (param) {
                                                      return Json_decode.list((function (__x) {
                                                                    return Issues_assignee$BsConsole.ofJson(__x, watcher);
                                                                  }), param);
                                                    }), param);
                                      }), json),
                                watcher: "_backtrace"
                              }]);
                  } else {
                    return /* Linked */Block.__(1, [{
                                short: Json_decode.field("short", Json_decode.string, json),
                                url: Json_decode.field("url", Json_decode.string, json),
                                assignees: Json_decode.optional((function (param) {
                                        return Json_decode.field("assignees", (function (param) {
                                                      return Json_decode.list((function (__x) {
                                                                    return Issues_assignee$BsConsole.ofJson(__x, watcher);
                                                                  }), param);
                                                    }), param);
                                      }), json),
                                watcher: watcher
                              }]);
                  }
                }),
              /* :: */[
                (function (json) {
                    return /* Unlinked */Block.__(0, [{
                                short: Json_decode.field("short", Json_decode.string, json),
                                url: Json_decode.field("url", Json_decode.string, json),
                                assignees: Json_decode.optional((function (param) {
                                        return Json_decode.field("assignees", (function (param) {
                                                      return Json_decode.list((function (__x) {
                                                                    return Issues_assignee$BsConsole.ofJson(__x, "_backtrace");
                                                                  }), param);
                                                    }), param);
                                      }), json)
                              }]);
                  }),
                /* [] */0
              ]
            ], json);
}

function toJson$3(tic) {
  switch (tic.tag | 0) {
    case /* Unlinked */0 :
        var tic$1 = tic[0];
        return Json_encode.object_(/* :: */[
                    /* tuple */[
                      "short",
                      tic$1.short
                    ],
                    /* :: */[
                      /* tuple */[
                        "url",
                        tic$1.url
                      ],
                      /* :: */[
                        /* tuple */[
                          "assignees",
                          Json_encode.nullable(toJson$2, tic$1.assignees)
                        ],
                        /* [] */0
                      ]
                    ]
                  ]);
    case /* Linked */1 :
        var tic$2 = tic[0];
        return Json_encode.object_(/* :: */[
                    /* tuple */[
                      "short",
                      tic$2.short
                    ],
                    /* :: */[
                      /* tuple */[
                        "url",
                        tic$2.url
                      ],
                      /* :: */[
                        /* tuple */[
                          "assignees",
                          Json_encode.nullable(toJson$2, tic$2.assignees)
                        ],
                        /* :: */[
                          /* tuple */[
                            "watcher",
                            tic$2.watcher
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ]);
    case /* InteralAssigneeLink */2 :
        return Json_encode.object_(/* :: */[
                    /* tuple */[
                      "assignees",
                      Json_encode.nullable(toJson$2, tic[0].assignees)
                    ],
                    /* :: */[
                      /* tuple */[
                        "watcher",
                        "_backtrace"
                      ],
                      /* [] */0
                    ]
                  ]);
    
  }
}

function getShort(t) {
  switch (t.tag | 0) {
    case /* Unlinked */0 :
    case /* Linked */1 :
        return t[0].short;
    case /* InteralAssigneeLink */2 :
        return "";
    
  }
}

function getUrl(t) {
  switch (t.tag | 0) {
    case /* Unlinked */0 :
    case /* Linked */1 :
        return t[0].url;
    case /* InteralAssigneeLink */2 :
        return ;
    
  }
}

function eq(t, t$prime) {
  switch (t.tag | 0) {
    case /* Unlinked */0 :
        var t$1 = t[0];
        switch (t$prime.tag | 0) {
          case /* Unlinked */0 :
              var t$prime$1 = t$prime[0];
              if (t$1.short === t$prime$1.short) {
                return t$1.url === t$prime$1.url;
              } else {
                return false;
              }
          case /* Linked */1 :
          case /* InteralAssigneeLink */2 :
              return false;
          
        }
    case /* Linked */1 :
        var t$2 = t[0];
        switch (t$prime.tag | 0) {
          case /* Linked */1 :
              var t$prime$2 = t$prime[0];
              if (t$2.short === t$prime$2.short && t$2.url === t$prime$2.url) {
                return t$2.watcher === t$prime$2.watcher;
              } else {
                return false;
              }
          case /* Unlinked */0 :
          case /* InteralAssigneeLink */2 :
              return false;
          
        }
    case /* InteralAssigneeLink */2 :
        return false;
    
  }
}

var Ticket = {
  setAssignees: setAssignees,
  makeUnlinked: makeUnlinked,
  makeBacktraceIssueTicket: makeBacktraceIssueTicket,
  ofJson: ofJson,
  toJson: toJson$3,
  getShort: getShort,
  getUrl: getUrl,
  eq: eq
};

function toJson$4(v) {
  return Json_encode.list(toJson$3, v);
}

function getBTs(t) {
  return Belt_Option.flatMap(Belt_Option.map(t, (function (t) {
                    var items = Belt_List.keep(t, (function (t) {
                            switch (t.tag | 0) {
                              case /* Unlinked */0 :
                              case /* Linked */1 :
                                  return false;
                              case /* InteralAssigneeLink */2 :
                                  return true;
                              
                            }
                          }));
                    if (items) {
                      return items;
                    }
                    
                  })), (function (d) {
                return d;
              }));
}

function getUnlinked(t) {
  return Belt_Option.flatMap(Belt_Option.map(t, (function (t) {
                    var items = Belt_List.keep(t, (function (t) {
                            switch (t.tag | 0) {
                              case /* Unlinked */0 :
                                  return true;
                              case /* Linked */1 :
                              case /* InteralAssigneeLink */2 :
                                  return false;
                              
                            }
                          }));
                    if (items) {
                      return items;
                    }
                    
                  })), (function (d) {
                return d;
              }));
}

function getLinked(t) {
  return Belt_Option.flatMap(t, (function (t) {
                var items = Belt_List.keep(t, (function (t) {
                        switch (t.tag | 0) {
                          case /* Linked */1 :
                              return true;
                          case /* Unlinked */0 :
                          case /* InteralAssigneeLink */2 :
                              return false;
                          
                        }
                      }));
                if (items) {
                  return items;
                }
                
              }));
}

function getLinkedAndUnlinked(t) {
  return Belt_List.toArray(Belt_List.keep(t, (function (ticket) {
                    switch (ticket.tag | 0) {
                      case /* Unlinked */0 :
                      case /* Linked */1 :
                          return true;
                      case /* InteralAssigneeLink */2 :
                          return false;
                      
                    }
                  })));
}

var Tickets = {
  toJson: toJson$4,
  columnName: "fingerprint;issues;ticket",
  getBTs: getBTs,
  getUnlinked: getUnlinked,
  getLinked: getLinked,
  getLinkedAndUnlinked: getLinkedAndUnlinked
};

function toJson$5(v) {
  var tmp;
  if (typeof v === "number") {
    tmp = [];
  } else {
    switch (v.tag | 0) {
      case /* Time */0 :
          tmp = [Json_encode.object_(/* :: */[
                  /* tuple */[
                    "type",
                    "timestamp_gt"
                  ],
                  /* :: */[
                    /* tuple */[
                      "timestamp",
                      v[0]
                    ],
                    /* [] */0
                  ]
                ])];
          break;
      case /* VersionGt */1 :
          var match = v[0];
          tmp = [Json_encode.object_(/* :: */[
                  /* tuple */[
                    "type",
                    "version_gt"
                  ],
                  /* :: */[
                    /* tuple */[
                      "field",
                      match[0]
                    ],
                    /* :: */[
                      /* tuple */[
                        "version",
                        match[1]
                      ],
                      /* [] */0
                    ]
                  ]
                ])];
          break;
      case /* VersionGe */2 :
          var match$1 = v[0];
          tmp = [Json_encode.object_(/* :: */[
                  /* tuple */[
                    "type",
                    "version_ge"
                  ],
                  /* :: */[
                    /* tuple */[
                      "field",
                      match$1[0]
                    ],
                    /* :: */[
                      /* tuple */[
                        "version",
                        match$1[1]
                      ],
                      /* [] */0
                    ]
                  ]
                ])];
          break;
      
    }
  }
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "action",
                "set"
              ],
              /* :: */[
                /* tuple */[
                  "value",
                  tmp
                ],
                /* [] */0
              ]
            ]);
}

function fromJsonStr(str) {
  if (str === undefined) {
    return ;
  }
  try {
    var json = JSON.parse(str);
    var item = function (json) {
      return {
              type_: Json_decode.optional((function (param) {
                      return Json_decode.field("type", Json_decode.string, param);
                    }), json),
              timestamp: Json_decode.optional((function (param) {
                      return Json_decode.field("timestamp", Json_decode.$$int, param);
                    }), json),
              field: Json_decode.optional((function (param) {
                      return Json_decode.field("field", Json_decode.string, param);
                    }), json),
              version: Json_decode.optional((function (param) {
                      return Json_decode.field("version", Json_decode.string, param);
                    }), json)
            };
    };
    var all = Json_decode.array(item, json);
    var first = Belt_Array.get(all, 0);
    if (first === undefined) {
      return ;
    }
    var match = first.type_;
    var match$1 = first.timestamp;
    var match$2 = first.field;
    var match$3 = first.version;
    if (match === undefined) {
      return ;
    }
    switch (match) {
      case "timestamp_gt" :
          if (match$1 !== undefined) {
            return /* Time */Block.__(0, [String(match$1)]);
          } else {
            return ;
          }
      case "version_ge" :
          if (match$2 !== undefined && match$3 !== undefined) {
            return /* VersionGe */Block.__(2, [/* tuple */[
                        match$2,
                        match$3
                      ]]);
          } else {
            return ;
          }
      case "version_gt" :
          if (match$2 !== undefined && match$3 !== undefined) {
            return /* VersionGt */Block.__(1, [/* tuple */[
                        match$2,
                        match$3
                      ]]);
          } else {
            return ;
          }
      default:
        return ;
    }
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e[0] === Js_exn.$$Error) {
      var message = e[1].message;
      if (message !== undefined) {
        console.log("Error: " + (String(message) + ""));
        return ;
      } else {
        console.log("An unknown error occurred");
        return ;
      }
    }
    throw e;
  }
}

function toString$2(t) {
  if (typeof t === "number") {
    return "";
  }
  switch (t.tag | 0) {
    case /* Time */0 :
        return t[0];
    case /* VersionGt */1 :
    case /* VersionGe */2 :
        break;
    
  }
  var match = t[0];
  return match[0] + match[1];
}

function toDescriptiveString(t) {
  if (typeof t === "number") {
    return "";
  }
  switch (t.tag | 0) {
    case /* Time */0 :
        var timestamp = t[0];
        if (timestamp === "1") {
          return I18N$BsConsole.get(undefined, "seen again");
        } else {
          return Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                          /* String_literal */Block.__(11, [
                              "seen after ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ]),
                          "seen after %s"
                        ]), L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, new Date(Caml_format.caml_float_of_string(timestamp) * 1000)));
        }
    case /* VersionGt */1 :
        var match = t[0];
        return Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "seen in ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " > ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ])
                              ])
                          ]),
                        "seen in %s > %s"
                      ]), match[0], match[1]);
    case /* VersionGe */2 :
        var match$1 = t[0];
        return Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "seen in ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " >= ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ])
                              ])
                          ]),
                        "seen in %s >= %s"
                      ]), match$1[0], match$1[1]);
    
  }
}

var Invariant = {
  columnName: "fingerprint;issues;invariants",
  toJson: toJson$5,
  fromJsonStr: fromJsonStr,
  toString: toString$2,
  toDescriptiveString: toDescriptiveString
};

var toString$3 = Base$BsConsole.toString;

function toJson$6(v) {
  return v;
}

var Id = {
  toString: toString$3,
  toJson: toJson$6,
  columnName: "fingerprint;issues;id"
};

var Assignee;

exports.Column = Column;
exports.State = State;
exports.Tag = Tag;
exports.Tags = Tags;
exports.Assignee = Assignee;
exports.Assignees = Assignees;
exports.Ticket = Ticket;
exports.Tickets = Tickets;
exports.Invariant = Invariant;
exports.Id = Id;
/* Css Not a pure module */
